import React from "react"
import axios from 'axios';
import { navigate } from "gatsby"


import Province from "../components/age-components/province"
import Month from "../components/age-components/month"
import Day from "../components/age-components/day"
import Year from "../components/age-components/year"


export default class form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName:"",
            lastName:"",
            email:"",
            newsletter:"",
            month:"",
            day:"",
            year:"",
            province:"",
            local:"en",
            formErrors: { firstName:false, lastName:false, email:false, province: false, month: false, day: false, year: false, age: false, newsletter:false,qc: false, email_valid:false, lastName_label:"", firstName_label:""},
            formValid: false,
            hurrah: false
        }
    }
    componentDidMount(){
        let age = window.sessionStorage.getItem('age');
        if(age){
            age = JSON.parse(age);
            this.setState({month:age.month, day:age.day, year:age.year, province:age.province});            
        }
    }
    handleBlur = event => {
        const target = event.target
        const name = target.name
        this.validateForm(name)
    }
    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name
        this.setState({
          [name]: value,
        })
    }
    handleSubmit = event => {
        
        event.preventDefault();
        
        var formValid = this.validateForm();
        
        if(formValid){
            this.sendFormData();
        }
    }
    validateForm(inject_field_key){
        let form_details = {
            firstName:this.state.firstName,
            lastName:this.state.lastName,
            email:this.state.email,
            province:this.state.province,
            month:this.state.month,
            day:this.state.day,
            year:this.state.year,
            newsletter: this.state.newsletter
        }
        //Create Array of age details to loop through
        var field_keys;
        if(inject_field_key){
            field_keys = [inject_field_key]
        }else{
            field_keys = Object.keys(form_details);
        }
        var tempFormErrors = this.state.formErrors;
        var formValid = true;
        
        for(var field_key of field_keys){
            //Loop through form fields, if one is left empty flag it as an error in formErrors state object
            console.log(field_key, form_details[field_key]);
            if(form_details[field_key] === ""){
                tempFormErrors[field_key] = true;
                formValid = false;
                if(field_key === "firstName" || field_key === "lastName"){
                    tempFormErrors[field_key+"_label"] = "";
                }
            }else{
                if(field_key === "firstName" || field_key === "lastName"){
                    var format = /[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?]/;
                    if(format.test(form_details[field_key])){
                        tempFormErrors[field_key+"_label"] = "Oops. You can’t use special characters here. Try again?";
                        tempFormErrors[field_key] = true;
                        formValid = false;
                    }
                    else if(/\s/g.test(form_details[field_key])){
                        tempFormErrors[field_key+"_label"] = "Oops. Did you add an extra space? Try again!";
                        tempFormErrors[field_key] = true;
                        formValid = false;
                    }
                    else if(/^-/.test(form_details[field_key]) || /-$/.test(form_details[field_key])){
                        tempFormErrors[field_key+"_label"] = "Hrm. Is that where the hyphen goes. Try again?";
                        tempFormErrors[field_key] = true;
                        formValid = false;
                    }
                    else{
                        tempFormErrors[field_key+"_label"] = "";
                        tempFormErrors[field_key] = false;                        
                    }
                }
                else if(field_key === "email"){
                    var format = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if(!format.test(form_details[field_key])){
                        tempFormErrors["email_valid"] = true;
                        formValid = false;
                    }else{
                        tempFormErrors["email_valid"] = false;    
                        tempFormErrors[field_key] = false;
                    }
                }
                else{
                    tempFormErrors[field_key] = false;
                }
            }
        }
        // If we have errors stop here and set the error state object.
        

        //if(!formValid){ return; }

        //Checking for legal age as per province
        //var tempFormErrors = this.state.formErrors;
        if(!tempFormErrors.month && !tempFormErrors.day && !tempFormErrors.year && !tempFormErrors.province){
            var age = this.getAge(this.state.year+"/"+this.state.month+"/"+this.state.day);
            tempFormErrors.qc = false;
            if(this.state.province === "AB"){
                if(age < 18){
                    tempFormErrors.age = true;
                    formValid = false;
                }else{
                    tempFormErrors.age = false;
                }
            }else if(this.state.province === "QC"){
                tempFormErrors.qc = true;
                formValid = false;
            }
            if(age < 19){
                tempFormErrors.age = true;
                formValid = false;
            }else{
                tempFormErrors.age = false;
            }
            
        }
        // Set the error state object.
        this.setState({formErrors: tempFormErrors});
        
        // Check if form is valid. If so return true
        return formValid;
        
    }
    sendFormData(){        
        axios.post('/api/post.php', {data: this.state})
        .then(res => {
            this.setState({hurrah: true})
            // navigate("/thank-you/")
        })
    }
    isValid(field_key){
        if(this.state.formErrors[field_key]){
            return "error ";
        }else{
            return "";
        }
    }
    getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
    render() {
        return(
            //my-selector
            <section id="entry-form" className={"pt-5 d-flex flex-column justify-content-center hurrah-" +this.state.hurrah}>
                {this.state.hurrah === false ? (
                <form className="mb-2 mb-md-0 container" onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1 col-md-10 offset-md-1">
                            <h3>let’s be<br/>inbox buddies</h3>  
                            <p >All fields are required, ok?</p>                        

                            <div className="form-row">
                                <div className={"col-md-6 form-group " + this.isValid("firstName")}>
                                    <label htmlFor="lead_firstName">first name</label>
                                    <input type="text" className="form-control" id="lead_firstName" name="firstName" value={this.state.firstName} onChange={this.handleInputChange} onBlur={this.handleBlur}/>                 {this.state.formErrors['firstName_label'] !== "" ? (
                                        <small className={"error-message"}>{this.state.formErrors['firstName_label']}</small>
                                    ) : (
                                        <small className="error-message">You forgot to add your name!</small>
                                    )}
                                </div>
                                <div className={"col-md-6 form-group " + this.isValid("lastName")}>
                                    <label htmlFor="lead_lastName">last name</label>
                                    <input type="text" className="form-control" id="lead_lastName" name="lastName" value={this.state.lastName} onChange={this.handleInputChange} onBlur={this.handleBlur} />                 

                                    {this.state.formErrors['lastName_label'] === "" ? (
                                        <small className="error-message">You forgot to add your name!</small>                                        
                                    ) : (
                                        <small className={"error-message"}>{this.state.formErrors['lastName_label']}</small>
                                    )}
                                </div>
                            </div>
                            
                            <div className={"form-group " + this.isValid("email") + this.isValid("email_valid")}>
                                <label htmlFor="lead_email">email</label>
                                <input type="text" className="form-control" id="lead_email" name="email" value={this.state.email} onChange={this.handleInputChange} onBlur={this.handleBlur} />                                
                                {this.isValid("email_valid") === "error " ? (
                                    <small className={"error-message"}>Hrm. Your email isn’t in the right format. Try again?</small>  
                                ) : (
                                    <small className="error-message">You forgot to add your email!</small>
                                )}                        
                            </div>
                            
                            <div className="form-row">
                                <div className="col-md-7">
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div className={"form-group " + this.isValid("province")}>
                                                <label htmlFor="lead_province">province/territory</label>                                
                                                <Province id="lead_province" province={this.state.province} name="province" handleInputChange={this.handleInputChange}/>
                                                <small className="error-message">You forgot to add your province/territory!</small>                               
                                            </div>
                                            <p className={"age-message text-center "+this.isValid("qc")}><small>Boo. Chowie isn’t available in your province. </small></p>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-row">
                                                <div className={"mb-4 mb-md-0 col-md-5 form-group " + this.isValid("month") + this.isValid("day") + this.isValid("year")}>
                                                    <label className=" mt-2" htmlFor="lead_month">month</label>
                                                    <Month id="lead_month" month={this.state.month} name="month" handleInputChange={this.handleInputChange}/>
                                                </div>
                                                <div className={"mb-4 mb-md-0 col-md-3 form-group " + this.isValid("day")}>
                                                <label className=" mt-2" htmlFor="lead_day">day</label>
                                                    <Day id="lead_day"  day={this.state.day} name="day" handleInputChange={this.handleInputChange}/>
                                                </div>
                                                <div className={"mb-4 mb-md-0 col-md-4 form-group " + this.isValid("year")}>
                                                    <label className="mt-2" htmlFor="lead_year">year</label>
                                                    <Year id="lead_year" year={this.state.year} name="year" handleInputChange={this.handleInputChange}/>
                                                </div>
                                                <small className={"error-message d-block text-left mb-1 "+this.isValid("month") + this.isValid("day") + this.isValid("year")}>You forgot to add your date of birth!</small>
                                            </div>
                                            <p className={"age-message text-center "+this.isValid("age")}><small>Oops. You’re not old enough to chow in your province.</small></p>
                                        </div>
                                        <div className={"form-group col-md-12 mt-4 " + this.isValid("newsletter")}>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="gridCheck" name="newsletter" checked={this.state.newsletter} onChange={this.handleInputChange} />
                                                <label className="form-check-label" htmlFor="gridCheck">
                                                Yes! I sure do want to receive emails from Chowie&nbsp;Wowie. You can unsubscribe any time.
                                                </label>
                                                <small className="error-message">Please confirm you would like to receive email communications.</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 text-center text-md-right">
                                    <button type="submit" className="mt-4 mt-md-4 mb-1 mb-md-0 btn btn-primary">sign up</button>
                                </div>
                            </div>                                                        
                            
                        </div>
                    </div>
                </form>
                ) : (
                    <div className="mb-2 mb-md-0 container">
                        <div className="row">
                            <div className="col-xl-10 offset-xl-1 col-md-10 offset-md-1">
                                <h3>hurrah!</h3>
                                <p>Email sign up success! Expect an email soon.</p>
                            </div>
                        </div>
                    </div>
                )}
            </section>
        )
    }
}