import React from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/sass/main.scss';

import Form from "../components/form"
import AgeGate from "../components/age-gate"
import Footer from "../components/footer"

/*Assets*/
import down_chevron from "../assets/images/down-chevron.png"
import red_chevron from "../assets/images/red-chevron.png"
import bring_the_wow from "../assets/images/bring_the_wow.png"

    /* Product Images */
    import Watermelon from "../assets/images/Edibles-Pouch-Mockup_Chowie-Wowie_Watermelon.png"
    import Milk_Balance from "../assets/images/Edibles-Pouch-Mockup_Chowie-Wowie_Milk-Balance.png"
    import Pineapple from "../assets/images/Edibles-Pouch-Mockup_Chowie-Wowie_Pineapple-Col-3.png"
    import MilkTHC from "../assets/images/Edibles-Pouch-Mockup_Chowie-Wowie_MilkTHC.png"

    import pink_total from "../assets/images/pink_total.png"
    import yellow_total from "../assets/images/yellow_total.png"
    import teal_total from "../assets/images/teal_total.png"
    import green_total from "../assets/images/green_total.png"


export default class homepage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {products:[
            // {
            //     opened: false,
            //     theme:'blue',
            //     title:{__html:"hark! it’s dark!"},
            //     category: "CBD chocolates",
            //     description:"Milk chocolate’s fancy dress-wearing, sophisticated twin. Start with 1 square then wait to feel the effects. While you wait you could count tiles in your backsplash, knit cat mittens, get to inbox zero...",
            //     thumbnail:Dark_CBD, 
            //     thc_total:"", thc_serving:'',
            //     cbd_total:"20mg", cbd_serving:'10mg',
            //     per_pack:"1 bar",
            //     servings:"2"
            // },
            {
                opened: false,
                theme:'pink',
                title:{__html:"carpe<br/>melon"},
                category: "THC gummies",
                description:"Oh so subtle and not too sweet, in true watermelon style. Start with 1 gummy then wait to feel the effects. While you wait you could learn a bird call, sing showtunes, start a time capsule...",
                thumbnail:Watermelon,
                thumbnailAlt:"Chowie Wowie watermelon gummies - 4 per pack (10:0 THC:CBD)",
                total_image:pink_total,
                totalImageAlt: "total THC: 10 mg",
                thc_total:"10mg", thc_serving:'2.5mg',
                cbd_total:"", cbd_serving:'',
                per_pack:"4 gummies",
                servings:"4",
                next:'.yellow'
            },
            {
                opened: false,
                theme:'yellow',
                title:{__html:"lil&nbsp;this<br/>lil&nbsp;that"},
                category: "balanced gummies",
                description:"A pineapple mango tango in your mouth. Start with 1 gummy then wait to feel the effects. While you wait you could organize your sock drawer, memorize the alphabet backwards, start your autobiography...",
                thumbnail:Pineapple,
                thumbnailAlt:"Chowie Wowie pineapple mango gummies - 4 per pack (10:0 THC:CBD)",
                total_image:yellow_total,
                totalImageAlt: "total THC: 10 mg total CBD: 10 mg",
                thc_total:"10mg", thc_serving:'2.5mg',
                cbd_total:"10mg", cbd_serving:'2.5mg',
                per_pack:"4 gummies",
                servings:"4",
                next:'.teal'
            },
            {
                opened: false,
                theme:'teal',
                title:{__html:"splits<br/>ville"},
                category: "balanced chocolates",
                description:"Chocolate so smooth it could sell water to a fish. Start with 1 square then wait to feel the effects. While you wait you could invent backstories for strangers, try not to think about capybaras, watch clouds...",
                thumbnail:MilkTHC,
                thumbnailAlt:"Chowie Wowie milk chocolate (10:10 THC:CBD)",
                total_image:teal_total,
                totalImageAlt: "total THC 10mg total CBD 10mg",
                thc_total:"10mg", thc_serving:'5mg',
                cbd_total:"10mg", cbd_serving:'5mg',
                per_pack:"1 bar",
                servings:"2",
                next:'.green'
            },
            {
                opened: false,
                theme:'green',
                title:{__html:"wonder<br/>-full"},
                category: "THC chocolates",
                description:"Smoooooooth like butttaaahhhh. Start with 1 square then wait to feel the effects. While you wait you could line dance, paint a still life of spaghetti, listen to an album at 1.5x speed...",
                thumbnail:Milk_Balance, 
                thumbnailAlt:"Chowie Wowie milk chocolate (10:0 THC:CBD)",
                total_image:green_total,
                totalImageAlt: "total THC 10mg",
                thc_total:"10mg", thc_serving:'5mg',
                cbd_total:"", cbd_serving:'',
                per_pack:"1 bar",
                servings:"2",
                next:'#entry-form'
            }
            
        ]};
    }
    ToggleProductState(index){
        var products = this.state.products;
        products[index].opened = !products[index].opened;
        this.setState({products});
    }
    ProductView(element, index){
        return(
            <section className={"product-list pt-0 pt-md-2 pb-md-4 pb-0 d-flex flex-column justify-content-center "+element.theme+(element.opened ? " 1" : " 2")}>
                    <div className="container">
                        <div className={"row " + (element.opened ? "open" : "")}>
                            <div className=" col-md-12 ">
                                <article className={"row align-items-center"}>
                                    <div className="col-10 offset-1 col-md-5 offset-md-0 product-image text-center">
                                        <img src={element.thumbnail} alt={element.thumbnailAlt}/>
                                        <button tabindex="0" onClick={() => this.ToggleProductState(index)} className="btn btn-primary">see more!</button>
                                    </div>
                                    <div className={"offset-md-1 col-md-6 mt-1 product-info mt-md-5 mt-md-0 "+(element.opened ? "" : "")}>
                                        <button onClick={() => this.ToggleProductState(index)} className="close-product">close</button>
                                        <h2 dangerouslySetInnerHTML={element.title}></h2>
                                        <div className="row">
                                            <div className="col-md-10">
                                                <p className="pt-3 pt-md-0"><strong className="larger-bold themed">{element.category}</strong></p>
                                                <p>{element.description}</p>
                                            </div>
                                        </div>
                                        <img className="mb-4 mt-3" src={element.total_image} alt={element.totalImageAlt}/>
                                        <div className="d-flex product-specs">
                                            <div className="">
                                                <strong className="black">per pack</strong><br/>
                                                <strong className="smaller-bold">{element.per_pack}</strong>
                                            </div>   
                                            <div className="text-center">
                                                <strong className="black">servings</strong><br/>
                                                <strong className="smaller-bold">{element.servings}</strong>
                                            </div>   
                                            <div className="">
                                                <strong className="black">per serving</strong><br/>
                                                <strong className="smaller-bold">{(element.thc_serving ? element.thc_serving + " THC" : "")}<br/>
                                                {(element.cbd_serving !== "" ? element.cbd_serving + " CBD" : "")}</strong>
                                            </div>                                                
                                        </div>
                                    </div>
                                    
                                </article>
                            </div>                            
                        </div>
                   </div>
                   <img onClick={() => {this.scrollSectionDown(this.state.products[index].next)}} className="down-chevron" src={(element.theme === 'yellow' ? red_chevron : down_chevron)} alt="arrow" />
            </section>
        )
    }
    scrollSectionDown(anchor){
        document.querySelector(anchor).scrollIntoView({
            behavior: 'smooth'
        });
        
    }
    render(){
        return(
            <div id="home-page" className="">
                <AgeGate/>
                <section className="about py-2 d-flex flex-column justify-content-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-10 offset-xl-1 col-md-10 offset-md-1">
                                <img className="bring_the_wow" src={bring_the_wow} alt="Chowie Wowie logo bring the wow"/>
                                <h1 className="d-none">Chowie wowie Bring the wow.</h1>
                                <p><strong>a wow you can maow</strong> Take a ray of sunshine. Add colour, joy, and a double-shot of pick-me-up and you’re halfway to Chowie Wowie. We think edibles should be fun, safe, tasty, and consistent. And most important of all, enjoyed with friends.</p>                                
                            </div>
                        </div>   
                    </div>
                    <img onClick={() => {this.scrollSectionDown('.pink')}} className="down-chevron mt-3" src={down_chevron} alt="arrow" />
    
                </section>
                
                {this.ProductView(this.state.products[0], 0)}

                {this.ProductView(this.state.products[1], 1)}

                {this.ProductView(this.state.products[2], 2)}

                {this.ProductView(this.state.products[3], 3)}

                <Form />
                
                <Footer />
            </div>
        )
    }
    
}